import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {css, Global} from '@emotion/react';
import styled from '@emotion/styled';
import {graphql} from 'gatsby';
import Seo from '~components/Seo';
import DataViewer from '~utils/DataViewer';

import Section from '~components/Section';
import Image from '~components/Image';
import {useSiteState} from '~context/siteContext';
import {mobile, tablet} from '~styles/global';
import MarqueeRow from '~components/MarqueeRow';

import {Plus} from '~components/Svg';
import RichText from '~components/RichText';

const StudioPage = ({data}) => {
	const {page} = data;
	const [slideIndex, setSlideIndex] = useState(0);
	const [slideIndexMobile, setSlideIndexMobile] = useState(0);
	const [siteState, setSiteState] = useSiteState();

	useEffect(() => {
		setSiteState((prevState) => ({
			...prevState,
			pageTitle: 'Studio',
		}));
	}, []);

	useEffect(() => {
		let timer;

		// Restart the timer
		timer = setTimeout(() => {
			if (slideIndex === page?.backgroundSlides?.length - 1) {
				setSlideIndex(0);
			} else {
				setSlideIndex(slideIndex + 1);
			}
		}, 2000);

		return () => clearTimeout(timer);
	}, [slideIndex, page]);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (slideIndexMobile === page?.backgroundSlidesMobile?.length - 1) {
				setSlideIndexMobile(0);
			} else {
				setSlideIndexMobile(slideIndexMobile + 1);
			}
		}, 2000);
		return () => clearTimeout(timer);
	});

	return (
		<>
			<Seo title={page.title} metaTitle={page.seo?.metaTitle} description={page.seo?.metaDescription} image={page.seo?.socialImage} />
			<Global
				styles={css`
					body {
						overflow: hidden;
						height: -webkit-fill-available;
						height: fill-available;
					}
				`}
			/>
			<Wrap>
				<BackgroundImages>
					{page?.backgroundSlides?.map((slide, i) => (
						<BackgroundImage key={slide?.asset?._id} image={slide} show={i === slideIndex} cover />
					))}
					{page?.backgroundSlidesMobile?.map((slide, i) => (
						<BackgroundImageMobile key={slide?.asset?._id} image={slide} show={i === slideIndexMobile} cover />
					))}
				</BackgroundImages>
				<TextWrapper>
					<RichText content={page?.text} />
					<TextList>
						{page?.textList.map((item, i) => (
							<li key={i}>
								<PlusIcon />
								{item}
							</li>
						))}
					</TextList>
				</TextWrapper>
				<Content hidden={siteState.menuOpen}>
					<LineSection>
						<Line />
					</LineSection>
					<Marquees>
						<MarqueeRow speed={0.8}>
							{page?.textList.map((item, i) => (
								<Item key={item}>
									<PlusIcon />
									{item}
								</Item>
							))}
						</MarqueeRow>
					</Marquees>
				</Content>
			</Wrap>
			<DataViewer data={data} name="data" />
		</>
	);
};

const Wrap = styled.div`
	min-height: 100vh;
	/* mobile viewport bug fix */
	min-height: -webkit-fill-available;
	min-height: fill-available;
`;
const BackgroundImages = styled.div`
	width: 100%;
	transition: filter 0.2s;
	min-height: 100vh;
	/* mobile viewport bug fix */
	max-height: -webkit-fill-available;
	height: fill-available;
`;

const BackgroundImage = styled(Image)`
	width: 100%;
	opacity: ${(props) => (props.show ? '1' : '0')};
	transition: opacity 0.8s;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	${mobile} {
		display: none;
	}
`;
const BackgroundImageMobile = styled(BackgroundImage)`
	display: none;
	${mobile} {
		display: block;
	}
`;
const Content = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding-bottom: 74px;
	mix-blend-mode: difference;
	transition: opacity 0.2s;
	overflow: hidden;
	${tablet} {
		display: none;
	}
	${mobile} {
		padding-bottom: 20px;
	}
`;
const TextWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	color: var(--white);
	flex-direction: column;
	justify-content: center;
	padding: 0 30px;
	overflow: hidden;
	${mobile} {
		padding-bottom: 20px;
		justify-content: flex-start;
		padding-top: 140px;
	}
	p {
		isolation: isolate;
		margin-bottom: 0px;
	}
`;

const TextList = styled.ul`
	display: none;
	list-style: none;
	mix-blend-mode: difference;
	${mobile} {
		display: block;
	}
`;

const LineSection = styled(Section)`
	display: none;
	${mobile} {
		display: block;
	}
`;
const Line = styled.div`
	${mobile} {
		grid-column: span 12;
		height: 1px;
		background-color: var(--white);
		width: 100%;
		margin-bottom: 4px;
	}
`;
const Marquees = styled.div`
	margin-bottom: 10px;
	mix-blend-mode: difference;
`;
const Item = styled.div`
	display: inline-block;
	color: var(--white);
	margin-right: 22px;
	font-size: 32px;
	line-height: 1.2;
	${mobile} {
		font-size: 20px;
	}
`;
const PlusIcon = styled(Plus)`
	width: 18px;
	height: 18px;
	margin-right: 4px;
	${mobile} {
		width: 10px;
		height: 10px;
	}
`;

export const query = graphql`
	query StudioQuery {
		page: sanityStudioPage {
			title
			seo {
				...seo
			}
			text: _rawPageContent(resolveReferences: {maxDepth: 5})
			textList
			backgroundSlides {
				...imageWithAlt
			}
			backgroundSlidesMobile {
				...imageWithAlt
			}
		}
	}
`;

StudioPage.propTypes = {
	data: PropTypes.object,
};

export default StudioPage;
